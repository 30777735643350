<div class="vi-report-container">
  <div>
    <div class="header-container"><p class="header">Filter by</p></div>
    <mat-expansion-panel [expanded]="true" class="report-date-time-selector-panel">
      <mat-expansion-panel-header class="report-date-time-selector-header">
        <p class="date-time-header">Date & Time</p>
      </mat-expansion-panel-header>
      <slb-radio-button-group
        [isVertical]="true"
        [values]="customOptionValues"
        (valueChanged)="onValueChangeCustom()"
        [(ngModel)]="selectedOption"></slb-radio-button-group>
      <slb-date-time-picker
        (valueChange)="setStartDate($event)"
        [label]="'From'"
        [required]="true"
        errorText="Field is required"
        [disabled]="selectedOption.length === 0"></slb-date-time-picker>
      <slb-date-time-picker
        (valueChange)="setEndDate($event)"
        [label]="'To'"
        [required]="true"
        errorText="Field is required"
        [disabled]="selectedOption.length === 0"></slb-date-time-picker>
      <div class="error-holder">
        <mat-error *ngIf="endDateError">End date should be greater than the start date</mat-error>
        <mat-error *ngIf="currentDateError">Start date and end date should be less than the current date</mat-error>
      </div>
      <slb-radio-button-group
        [isVertical]="true"
        [values]="timeRangeOptionValues"
        (valueChanged)="onValueChangeTimeRange()"
        [(ngModel)]="selectedDateRange">
      </slb-radio-button-group>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="gateways?.length" [expanded]="true" class="report-gateway-selector-panel">
      <mat-expansion-panel-header class="report-gateway-selector-header">
        <p class="site-header">Gateways</p>
      </mat-expansion-panel-header>
      <slb-radio-button-group [isVertical]="true" [values]="gateways" (valueChanged)="onGatewayChange()" [(ngModel)]="selectedGateway">
      </slb-radio-button-group>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="selectedEquipmentsForRadioButtons?.length" [expanded]="true" class="report-camera-selector-panel">
      <mat-expansion-panel-header class="report-camera-selector-header">
        <p class="site-header">Cameras</p>
      </mat-expansion-panel-header>
      <slb-radio-button-group [isVertical]="true" [values]="selectedEquipmentsForRadioButtons" [(ngModel)]="selectedEquipment">
      </slb-radio-button-group>
    </mat-expansion-panel>
    <div class="footer-button">
      <button class="clear-btn" slb-secondary-button type="button">Clear</button>
      <button
        class="apply-btn"
        slb-button
        (click)="getReportsData()"
        [disabled]="endDateError || currentDateError || disableApply || isLoading">
        Apply
      </button>
    </div>
  </div>
  <div>
    <div>
      <div class="vi-report-dashboard-timezone">
        <mat-form-field appearance="outline" floatLabel="always" id="vi_timezone_dropdown_reports">
          <mat-select
            [(ngModel)]="currentZoneDetails"
            (selectionChange)="onTimezoneChange()"
            class="vi-report-dashboard-timezone-select"
            hideSingleSelectionIndicator="true">
            <mat-option *ngFor="let zone of timezoneList" [value]="zone">
              {{ zone.label + ' ' + zone.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-card *ngIf="isLoading">
        <mat-card-content> <app-spinner></app-spinner> </mat-card-content>
      </mat-card>
      <app-report-tiles *ngIf="!isLoading" [lastReportingCycleData]="lastReportingCycleData"></app-report-tiles>
    </div>
    <div>
      <mat-card *ngIf="isLoading">
        <mat-card-content> <app-spinner></app-spinner> </mat-card-content>
      </mat-card>
      <app-report-charts
        *ngIf="!isLoading"
        [isLoading]="isLoading"
        [chartDetails]="chartDetails"
        [selectedTimeRangeText]="selectedTimeRangeText"></app-report-charts>
    </div>
    <div>
      <app-report-event
        [eventDetails]="eventDetails"
        [imageEventDetails]="imageEventDetails"
        [timeZone]="currentZoneDetails.timezone"
        (pageData)="getPaginatedData($event)"
        [startDate]="startDate"
        [endDate]="endDate"
        [showAllEvents]="showAllEvents"
        [totalRecords]="totalRecords"
        [equipmentId]="selectedEquipment"></app-report-event>
    </div>
  </div>
</div>
