import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import moment from 'moment';
import { HIGHCHARTCHARTDATA } from '../../../../src/app/shared/constants/camera-profile-constant';
import { LINECHARTOPTIONS, REPORT_PIE_CHART_COLORS } from '../../../../src/app/shared/constants/chart-constant';
import { DashboardConstants } from '../../../../src/app/shared/constants/dashboard-constants';
import { ReportViewConstant } from '../../../../src/app/shared/constants/report-constants';
import { ReportChartDetail } from '../../../../src/app/shared/models/report-view';

@Component({
  selector: 'app-report-charts',
  templateUrl: './report-charts.component.html',
  styleUrls: ['./report-charts.component.scss'],
})
export class ReportChartsComponent implements OnInit {
  @Input() chartDetails: {
    eventReportChartDetails: ReportChartDetail[];
    eventAtRiskDetails: { [keys: string]: number };
  };
  @Input() isLoading: boolean;
  @Input() selectedTimeRangeText: string;
  public eventLineChartOption: Highcharts.Options;
  public updateEventLineChart: boolean;
  public Highcharts = Highcharts;
  public eventTypeDistributionChartOption: Highcharts.Options;
  public updateEventTypeDistributionChart: boolean;

  constructor() {}

  ngOnInit(): void {
    if (this.chartDetails.eventReportChartDetails) {
      this.updateEventLineChart = false;
      this.updateEventTypeDistributionChart = false;
      this.createPeopleAtRiskChart();
      this.createEventTypeDistributionChart();
    } else {
      this.eventLineChartOption = JSON.parse(JSON.stringify(LINECHARTOPTIONS));
      this.eventTypeDistributionChartOption = JSON.parse(JSON.stringify(HIGHCHARTCHARTDATA));
    }
  }

  createPeopleAtRiskChart(): void {
    this.updateEventLineChart = false;
    this.eventLineChartOption = JSON.parse(JSON.stringify(LINECHARTOPTIONS));
    const seriesDataPeopleCount: any[] = [];
    const seriesDataAccumulatedRedzoneTime: any[] = [];
    const seriesDataEvents: any[] = [];
    this.eventLineChartOption.xAxis = {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%b %e',
      },
      labels: {
        style: {
          fontFamily: DashboardConstants.EVENTTYPELINECHARTFONT,
          fontSize: '10px',
        },
      },
      title: { text: 'Time' },
      gridLineColor: '#333',
    };
    this.chartDetails.eventReportChartDetails.forEach((e: ReportChartDetail) => {
      seriesDataPeopleCount.push([moment(e.endTime).valueOf(), e.peopleInRedZone ? e.peopleInRedZone : 0]);
      seriesDataAccumulatedRedzoneTime.push([moment(e.endTime).valueOf(), e.accumulativeTime ? e.accumulativeTime : 0]);
      seriesDataEvents.push([moment(e.endTime).valueOf(), e.eventCount ? e.eventCount : 0]);
    });
    this.eventLineChartOption.yAxis = [];
    this.eventLineChartOption.series = [];
    this.setYAxisForPeopleAtRiskChart();
    this.eventLineChartOption.series?.push({
      type: 'line',
      color: ReportViewConstant.getColorForTag('peopleInRedZone'),
      name: 'People in Redzone',
      data: seriesDataPeopleCount,
      yAxis: 0,
    });
    this.eventLineChartOption.series?.push({
      type: 'line',
      color: ReportViewConstant.getColorForTag('accumulativeTime'),
      name: 'Accumulated Redzone Time',
      data: seriesDataAccumulatedRedzoneTime,
      yAxis: 1,
    });
    this.eventLineChartOption.series?.push({
      type: 'line',
      color: ReportViewConstant.getColorForTag('eventCount'),
      name: 'Number of Events',
      data: seriesDataEvents,
      yAxis: 2,
    });
    this.updateEventLineChart = true;
  }

  setYAxisForPeopleAtRiskChart(): void {
    this.eventLineChartOption.yAxis = [
      {
        title: {
          text: 'People in Redzone',
          style: {
            fontFamily: DashboardConstants.EVENTTYPELINECHARTFONT,
            fontSize: '12px',
            fontWeight: '700',
            color: ReportViewConstant.getColorForTag('peopleInRedZone'),
          },
        },
        opposite: true,
        labels: {
          style: {
            fontFamily: DashboardConstants.EVENTTYPELINECHARTFONT,
            fontSize: '10px',
          },
        },
      },
      {
        title: {
          text: 'Accumulated Redzone Time',
          style: {
            fontFamily: DashboardConstants.EVENTTYPELINECHARTFONT,
            fontSize: '12px',
            fontWeight: '700',
            color: ReportViewConstant.getColorForTag('accumulativeTime'),
          },
        },
        labels: {
          style: {
            fontFamily: DashboardConstants.EVENTTYPELINECHARTFONT,
            fontSize: '10px',
          },
        },
      },
      {
        title: {
          text: 'Number of Events',
          style: {
            fontFamily: DashboardConstants.EVENTTYPELINECHARTFONT,
            fontSize: '12px',
            fontWeight: '700',
            color: ReportViewConstant.getColorForTag('eventCount'),
          },
        },
        labels: {
          style: {
            fontFamily: DashboardConstants.EVENTTYPELINECHARTFONT,
            fontSize: '10px',
          },
        },
      },
    ];
    this.eventLineChartOption.chart = {
      backgroundColor: '#fcfcfd',
      spacing: [0, 0, 0, -3],
    } as Highcharts.ChartOptions;
  }

  createEventTypeDistributionChart(): void {
    this.updateEventTypeDistributionChart = false;
    this.eventTypeDistributionChartOption = JSON.parse(JSON.stringify(HIGHCHARTCHARTDATA));
    this.eventTypeDistributionChartOption.series = [];
    this.eventTypeDistributionChartOption.yAxis = [];
    const options: Highcharts.YAxisOptions[] = [];
    for (let i = 0; i < Object.keys(this.chartDetails.eventAtRiskDetails).length; i++) {
      const name = Object.keys(this.chartDetails.eventAtRiskDetails)[i];
      const value = Object.values(this.chartDetails.eventAtRiskDetails)[i] as number;
      if (value > 0) {
        options.push({
          name,
          y: value,
          color: REPORT_PIE_CHART_COLORS[i],
          fillColor: REPORT_PIE_CHART_COLORS[i],
        } as Highcharts.YAxisOptions);
      }
    }
    this.eventTypeDistributionChartOption.series?.push({
      type: 'pie',
      innerSize: '50%',
      data: options,
      showInLegend: true,
      dataLabels: {
        enabled: true,
        distance: 10,
        format: '<b>{point.y}</b><br>{point.count}',
      },
    } as Highcharts.SeriesPieOptions);
    this.eventTypeDistributionChartOption.legend = {
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 100,
    };
    this.eventTypeDistributionChartOption.plotOptions = {
      pie: {
        size: 150,
      },
    };
    this.updateEventTypeDistributionChart = true;
  }
}
