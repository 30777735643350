import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';

export class ReportViewConstant {
  public static customOption: RadioButtonValue[] = [
    {
      value: 'custom',
      name: 'Custom',
      isDefault: false,
    },
  ];
  public static dateRange: RadioButtonValue[] = [
    {
      value: 'hour',
      name: '1 Hour',
      isDefault: false,
    },
    {
      value: 'day',
      name: 'Day',
      isDefault: false,
    },
    {
      value: 'week',
      name: 'Week',
      isDefault: false,
    },
    {
      value: 'month',
      name: 'Month',
      isDefault: false,
    },
  ];

  public static tagColors: { name: string; color: string }[] = [
    { name: 'peopleInRedZone', color: '#D400D4' },
    { name: 'accumulativeTime', color: '#3097A7' },
    { name: 'eventCount', color: '#4248FA' },
  ];

  public static getColorForTag(tagName: string): string | undefined {
    return this.tagColors.find(tag => tag.name === tagName) ? this.tagColors.find(tag => tag.name === tagName)?.color : '#000000';
  }
}

export const REPORT_EVENT_CSV_DOWNLOAD_HEADING = [
  'eventTime',
  'cameraName',
  'maxNoOfPersons',
  'peopleCount',
  'accumulativeTime',
  'avgPeopleCount',
  'entry',
  'exit',
  'reportingStartTime',
  'reportingEndTime',
  'quality',
  'cameraDownTime',
  'thumbnailFileUrl',
];

export const REPORT_TILE_TITLES = {
  NO_OF_ENTRIES: 'Number Of Entries',
  NO_OF_EXITS: 'Number Of Exits',
  ACCUMULATED_TIME_RED_ZONE: 'Accumulated Time In Redzone',
  PEOPLE_COUNT: 'People Count',
  AVG_TIME_REDZONE_PER_PERSON: 'Average Time In Redzone Per Person',
  MAX_PEOPLE_WORKING_SIMULTANEOUSLY_TOGETHER: 'Max People Working Simultaneously Together',
  CAMERA_DOWNTIME: 'Camera Downtime',
};

export const REPORT_DATE_FORMAT = 'MMM d, y, h:mm:ss a';
