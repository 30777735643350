<mat-card class="vi-report-event-main-container">
  <mat-card-header class="d-block" id="vi_report_event_card">
    <mat-card-title id="vi_report_event_title">
      <mat-checkbox
        class="checkbox"
        id="vi_report_event__checkbox"
        [(ngModel)]="checkAll"
        (change)="selectAllFiles($event)"
        [indeterminate]="
          eventDetails && selectedFiles && eventDetails.length !== selectedFiles.length && selectedFiles.length !== 0
        "></mat-checkbox>
      Events
    </mat-card-title>
  </mat-card-header>
  <div>
    <mat-button-toggle-group
      disableRipple="true"
      (change)="switchView($event)"
      id="vi_camera_profile_alerts_view_mode"
      [value]="viewMode"
      class="vi-report-event-view-toggle"
      [disabled]="dataNotAvailable">
      <mat-button-toggle value="grid"> Grid view </mat-button-toggle>
      <mat-button-toggle value="table"> Table view </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-icon
      *ngIf="!dataNotAvailable"
      svgIcon="download"
      aria-hidden="false"
      aria-label="Download"
      class="vi-report-event-view-download-icon"
      (click)="downloadSelectedFile()"
      [slbTooltip]="'Download Media'"></mat-icon>

    <mat-icon
      *ngIf="!dataNotAvailable"
      svgIcon="csv"
      aria-hidden="false"
      aria-label="Download"
      class="vi-report-event-view-download-csv-icon"
      (click)="downloadCSV()"
      [slbTooltip]="'Download CSV'"></mat-icon>
  </div>
  <mat-card *ngIf="(!eventDetails || eventDetails?.length === 0) && !dataNotAvailable">
    <mat-card-content> <app-spinner></app-spinner> </mat-card-content>
  </mat-card>
  <div
    *ngIf="viewMode === 'grid' && eventDetails?.length && !dataNotAvailable; else reportEventTable"
    class="vi-report-event-grid-container">
    <div *ngFor="let event of eventDetails; let i = index">
      <mat-card>
        <mat-card-header class="d-block" id="{{ 'vi_report_event_grid_card_' + i }}">
          <mat-card-title id="{{ 'vi_report_event_grid_card_title_' + i }}">
            <mat-checkbox
              class="checkbox"
              id="vi_report_event__checkbox"
              (change)="selectEvent($event, event)"
              [(ngModel)]="event.isChecked"></mat-checkbox>
            {{ event.eventTime | date : 'MMM d, y, h:mm:ss a' : timeZone }}
          </mat-card-title>
        </mat-card-header>
        <div>
          <img
            loading="lazy"
            *ngIf="event.thumbnailFileUrl; else errorImage"
            mat-card-image
            #eventImage
            class="cursor-pointer report-event-image"
            id="{{ 'vi_report_event_grid_card_image_' + i }}"
            src="{{ event.thumbnailFileUrl }}/?token={{ sessionToken }}"
            alt="IMAGE"
            (error)="eventImage.src = '../../assets/no-preview.svg'"
            (click)="onImageClick(i)" />
          <div class="overlay" (click)="onImageClick(i)">
            <span class="event-report-image-tooltip" matTooltip="{{ event.cameraName }}">Gateway: {{ event.cameraName }}</span
            ><span class="event-report-image-tooltip" matTooltip="{{ event.cameraName }}">Camera: {{ event.cameraName }}</span>
          </div>
          <ng-template #errorImage>
            <img
              mat-card-image
              class="cursor-pointer report-event-image no-prev"
              id="{{ 'vi_camera_profile_alerts_grid_card_image_error_' + i }}"
              src="../../../assets/no-preview.svg"
              (click)="onImageClick(i)" />
            <div class="overlay" (click)="onImageClick(i)">
              <span class="event-report-image-tooltip" matTooltip="{{ event?.cameraName }}">Gateway: {{ event.cameraName }}</span
              ><span class="event-report-image-tooltip" matTooltip="{{ event?.cameraName }}">Camera: {{ event.cameraName }}</span>
            </div>
          </ng-template>
        </div>
        <div class="vi-report-event-person-count">
          <div>
            <div>People Count: {{ event.peopleCount ? event.peopleCount : 0 }}</div>
            <div>Number of Exits: {{ event.exit ? event.exit : 0 }}</div>
          </div>
          <div>
            <div>Number of Entries: {{ event.entry ? event.entry : 0 }}</div>
            <div>Time in Redzone: {{ event.avgTimeInRedZone ? event.avgTimeInRedZone : 0 }}</div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <ng-template #reportEventTable>
    <div class="mat-elevation-z8" *ngIf="!dataNotAvailable">
      <table *ngIf="eventDetails?.length" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              class="checkbox"
              id="vi_report_event__checkbox"
              (change)="selectEvent($event, row)"
              [(ngModel)]="row.isChecked"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="eventTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Event time</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a (click)="onImageClick(i)" class="cursor-pointer">{{ row.eventTime | date : 'MMM d, y, h:mm:ss a' : timeZone }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="maxNoOfPersons">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Redzone People Limit</th>
          <td mat-cell *matCellDef="let row" class="table-cell">{{ row.maxNoOfPersons }}</td>
        </ng-container>

        <ng-container matColumnDef="reportingStartTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Reporting cycle start time</th>
          <td mat-cell *matCellDef="let row" class="table-cell">{{ row.reportingStartTime | date : 'MMM d, y, h:mm:ss a' : timeZone }}</td>
        </ng-container>

        <ng-container matColumnDef="reportingEndTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Reporting cycle end time</th>
          <td mat-cell *matCellDef="let row" class="table-cell">{{ row.reportingEndTime | date : 'MMM d, y, h:mm:ss a' : timeZone }}</td>
        </ng-container>

        <ng-container matColumnDef="peopleCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>People count</th>
          <td mat-cell *matCellDef="let row" class="table-cell">{{ row.peopleCount }}</td>
        </ng-container>

        <ng-container matColumnDef="entry">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of entries</th>
          <td mat-cell *matCellDef="let row" class="table-cell">{{ row.entry }}</td>
        </ng-container>

        <ng-container matColumnDef="exit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of exits</th>
          <td mat-cell *matCellDef="let row" class="table-cell">{{ row.exit }}</td>
        </ng-container>

        <ng-container matColumnDef="avgTimeInRedZone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Time in redzone (min)</th>
          <td mat-cell *matCellDef="let row" class="table-cell">{{ row.avgTimeInRedZone }}</td>
        </ng-container>

        <ng-container matColumnDef="accumulativeTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Accumulative time in</th>
          <td mat-cell *matCellDef="let row" class="table-cell">{{ row.accumulativeTime }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </ng-template>

  <div *ngIf="dataNotAvailable" class="report-event-no-data-text">No data available</div>

  <div class="pagination-wrapper">
    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100]"
      [length]="totalRecords"
      showFirstLastButtons
      (page)="pageEvent($event)"></mat-paginator>
  </div>
</mat-card>

<mat-drawer #drawer class="sidenav" fixedInViewport="true" position="end" [opened]="isOpen" disableClose>
  <app-event-popover
    *ngIf="popOverCameraDetails"
    [eventIndex]="selectedPopOverIndex"
    [hasNext]="hasNext"
    [hasPrevious]="hasPrevious"
    [cameraDetails]="popOverCameraDetails"
    (nextPrevEvent)="onEventClick($event)"
    (updateParentData)="updateParentData($event)"
    (closeSideInfo)="drawer.close(); closeCameraPopOver()"></app-event-popover>
</mat-drawer>

<slb-toast></slb-toast>
