import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from '../shared/services/report.service';
import { catchError, filter, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { GlobalViewConstant } from '../shared/constants/global-view-constant';
import { FormControl } from '@angular/forms';
import { ReportViewConstant } from '../shared/constants/report-constants';
import { Equipment } from '../shared/models/equipment';
import { ReportChartDetail, ReportDetail, ReportEventDetail } from '../shared/models/report-view';
import { GatewayDetail } from '../shared/models/gateway';
import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { PageEvent } from '@angular/material/paginator';
import { TimeZoneService } from '../shared/services/time-zone.service';
import { TimeZone } from '../shared/models/dashabordAlertData';
import { TIMEZONE } from '../shared/constants/timezone-constant';
import { CameraProfileService } from '../shared/services/camera-profile.service';
import { EventDetails } from '../shared/models/eventDetails';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnDestroy {
  public lastReportingCycleData: any;
  public chartDetails: {
    eventReportChartDetails: ReportChartDetail[];
    eventAtRiskDetails: { [keys: string]: number };
  };
  public formControl = new FormControl();
  public customOptionValues = ReportViewConstant.customOption;
  public timeRangeOptionValues = ReportViewConstant.dateRange;
  public selectedDateRange = '';
  public selectedOption = '';
  public currentGateway = '';
  public customStartDate: Date;
  public customEndDate: Date;
  public isLoading: boolean;
  public allEquipments: Equipment[];
  public gateways: RadioButtonValue[] = [];
  public selectedGateway: string;
  public selectedEquipments: Equipment[] = [];
  public selectedEquipmentsForRadioButtons: RadioButtonValue[] = [];
  public selectedEquipment = '';
  public eventDetails: ReportEventDetail[];
  public pageSize = 10;
  public pageIndex = 0;
  public currentZoneArea = '';
  public currentZoneDetails!: TimeZone;
  public timezoneList = TIMEZONE;
  public showAllEvents: string;
  public imageEventDetails: EventDetails[] = [];
  public startDate: Date;
  public endDate: Date;
  public totalRecords: number;
  public endDateError: boolean;
  public currentDateError: boolean;
  public disableApply: boolean;
  public selectedTimeRangeText: string;

  private destroyed = new Subject();

  constructor(
    private reportService: ReportService,
    private messageService: MessageService,
    private timeZoneService: TimeZoneService,
    private cameraProfileService: CameraProfileService,
    private datePipe: DatePipe
  ) {
    this.timeZoneService.timeZoneDetails$
      .pipe(
        filter((zone: TimeZone) => !!Object.keys(zone).length),
        tap((timeZoneDetails: TimeZone) => {
          this.currentZoneArea = timeZoneDetails?.label;
          this.currentZoneDetails = timeZoneDetails;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.getGatewayList();
    this.selectedDateRange = 'month';
    this.isLoading = true;
  }

  public onTimezoneChange(): void {
    this.timeZoneService.setSelectedTimeZone(this.currentZoneDetails);
    this.getReportsData();
  }

  getReportsData(): void {
    this.isLoading = true;
    this.eventDetails = [];
    this.startDate = this.getSelectedDateRange().startDate;
    this.endDate = this.getSelectedDateRange().endDate;
    this.setTitleForSelectedTimeRange();

    const requestPayload = {
      startDate: this.startDate.toISOString(),
      endDate: this.endDate.toISOString(),
      showAllEvents: '',
      pageNo: this.pageIndex,
      records: this.pageSize,
      timeZone: this.currentZoneDetails.timezone,
      equipmentId: this.selectedEquipment,
    };

    this.cameraProfileService.eventData$
      .pipe(
        tap(_value => {
          this.isLoading = true;
          this.eventDetails = [];
          this.showAllEvents = sessionStorage.getItem('showAllEvents') || '';
          requestPayload.showAllEvents = this.showAllEvents;
        }),
        switchMap(() =>
          this.reportService
            .getEventReport(
              requestPayload.startDate,
              requestPayload.endDate,
              requestPayload.showAllEvents,
              requestPayload.pageNo,
              requestPayload.records,
              requestPayload.timeZone,
              requestPayload.equipmentId
            )
            .pipe(
              tap((eventReportDetails: ReportDetail) => {
                this.lastReportingCycleData = eventReportDetails?.lastReportCycle;
                this.chartDetails = {
                  eventReportChartDetails: eventReportDetails.eventReportChartDetails,
                  eventAtRiskDetails: eventReportDetails.eventAtRisk,
                };
                this.eventDetails = eventReportDetails.eventReportTableDetails;
                this.totalRecords = eventReportDetails.total;
                this.imageEventDetails = eventReportDetails.eventDetails;
                this.isLoading = false;
              }),
              catchError(() => {
                this.messageService.add({
                  severity: SlbSeverity.Error,
                  summary: GlobalViewConstant.GLOBALSITEERROR,
                  closable: true,
                  sticky: true,
                });

                return of<any[]>({} as any[]);
              })
            )
        ),
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: GlobalViewConstant.GLOBALSITEERROR,
            closable: true,
            sticky: true,
          });

          return of<any[]>({} as any[]);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  getSelectedDateRange(): { startDate: Date; endDate: Date } {
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    if (this.selectedOption === 'custom') {
      startDate = new Date(this.customStartDate);
      endDate = new Date(this.customEndDate);
    } else {
      const currentDate = new Date();
      switch (this.selectedDateRange) {
        case 'hour':
          startDate = new Date(currentDate.setDate(currentDate.getHours() - 1));
          endDate = new Date();
          break;
        case 'day':
          startDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
          endDate = new Date();
          break;
        case 'week':
          startDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
          endDate = new Date();
          break;
        case 'month':
          startDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
          endDate = new Date();
          break;
        default:
          break;
      }
    }

    return { startDate, endDate };
  }

  setStartDate(event: Date): void {
    this.customStartDate = event;
  }

  setEndDate(event: Date): void {
    this.customEndDate = event;
    if (this.customStartDate && this.customEndDate && this.customStartDate?.getTime() >= this.customEndDate?.getTime()) {
      this.endDateError = true;
    } else {
      this.disableApply = false;
      this.endDateError = false;
    }

    if (
      (this.customStartDate && this.customStartDate?.getTime() > new Date().getTime()) ||
      (this.customEndDate && this.customEndDate?.getTime() > new Date().getTime())
    ) {
      this.currentDateError = true;
    } else {
      this.currentDateError = false;
      this.disableApply = false;
    }
  }

  onValueChangeCustom(): void {
    this.selectedDateRange = '';
    if (!this.customEndDate && !this.customEndDate) {
      this.disableApply = true;
    } else {
      this.disableApply = false;
    }
  }

  onValueChangeTimeRange(): void {
    this.selectedOption = '';
    this.disableApply = false;
  }

  getEquipmentList(): void {
    this.reportService
      .getEquipmentList()
      .pipe(
        tap((allEquipments: Equipment[]) => {
          this.allEquipments = allEquipments;
          this.onGatewayChange();
          this.getReportsData();
        }),
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: GlobalViewConstant.GLOBALSITEERROR,
            closable: true,
            sticky: true,
          });

          return of<any[]>({} as any[]);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  getGatewayList(): void {
    this.reportService
      .getGateways()
      .pipe(
        tap((allGateways: GatewayDetail[]) => {
          allGateways.forEach((gateway: GatewayDetail) => {
            this.gateways.push({
              value: gateway.id,
              name: gateway.name,
              isDefault: false,
            });
          });
          this.selectedGateway = this.gateways[0].value;
          this.getEquipmentList();
        }),
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: GlobalViewConstant.GLOBALSITEERROR,
            closable: true,
            sticky: true,
          });

          return of<any[]>({} as any[]);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  onGatewayChange(): void {
    this.selectedEquipments = [];
    this.selectedEquipments = this.allEquipments.filter(equipment => equipment.gatewayId === this.selectedGateway);
    this.selectedEquipmentsForRadioButtons = [];
    this.selectedEquipments.forEach((equipment: Equipment) => {
      this.selectedEquipmentsForRadioButtons.push({
        value: equipment.id,
        name: equipment.name,
        isDefault: false,
      });
    });
    this.selectedEquipment = this.selectedEquipmentsForRadioButtons[0].value;
  }

  onEquipmentChange(): void {
    this.getReportsData();
  }

  getPaginatedData(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getReportsData();
  }

  setTitleForSelectedTimeRange(): void {
    let selectedTimeRangeText = '';
    if (this.selectedOption === 'custom') {
      selectedTimeRangeText =
        this.datePipe.transform(this.startDate, 'medium', this.currentZoneDetails.timezone) +
        ' to ' +
        this.datePipe.transform(this.endDate, 'medium', this.currentZoneDetails.timezone);
    } else {
      switch (this.selectedDateRange) {
        case 'day':
          selectedTimeRangeText = 'Last 24 Hours';
          break;
        case 'week':
          selectedTimeRangeText = 'Last Week';
          break;
        case 'month':
          selectedTimeRangeText = 'Last Month';
          break;
        default:
          break;
      }
    }
    this.selectedTimeRangeText = selectedTimeRangeText;
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
