import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReportEventDataIteration, ReportEventDetail } from '../../../../src/app/shared/models/report-view';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { FILE_DOWNLOAD_ERROR } from '../../../../src/app/shared/constants/camera-profile-constant';
import { CameraProfileService } from '../../../../src/app/shared/services/camera-profile.service';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { EventDataIteration, EventDetails } from '../../../../src/app/shared/models/eventDetails';
import { DatePipe } from '@angular/common';
import { CameraConstants } from '../../../../src/app/shared/constants/camera-constant';
import { ReportService } from '../../../../src/app/shared/services/report.service';
import { REPORT_EVENT_CSV_DOWNLOAD_HEADING } from '../../../../src/app/shared/constants/report-constants';

@Component({
  selector: 'app-report-event',
  templateUrl: './report-event.component.html',
  styleUrls: ['./report-event.component.scss'],
})
export class ReportEventComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() eventDetails: ReportEventDetail[];
  @Input() imageEventDetails: EventDetails[];
  @Input() timeZone: string;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() showAllEvents: string;
  @Input() equipmentId: string;
  @Input() totalRecords: number;
  @Output() pageData: EventEmitter<PageEvent> = new EventEmitter<any>();
  @Output() cameraPopOverClosed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public sessionToken: string;
  public viewMode = 'grid';
  public displayedColumns: string[] = [
    'select',
    'eventTime',
    'maxNoOfPersons',
    'reportingStartTime',
    'reportingEndTime',
    'peopleCount',
    'entry',
    'exit',
    'avgTimeInRedZone',
    'accumulativeTime',
  ];
  public dataSource: MatTableDataSource<ReportEventDetail>;
  public checkAll: boolean;
  public selectedFiles: ReportEventDetail[] = [];
  public popOverCameraDetails: EventDetails | null;
  public hasNext = false;
  public hasPrevious = false;
  public selectedPopOverIndex = -1;
  public isOpen = false;
  public paginatedData: PageEvent;
  public isDownloadStarted: boolean;
  public dataNotAvailable: boolean;

  private destroyed = new Subject();

  constructor(
    private messageService: MessageService,
    private cameraProfileService: CameraProfileService,
    private datePipe: DatePipe,
    private reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    this.dataSource = new MatTableDataSource(this.eventDetails);
  }

  ngOnChanges(): void {
    if (this.eventDetails === null) {
      this.dataNotAvailable = true;
    } else {
      this.dataNotAvailable = false;
    }
    if (this.eventDetails?.length) {
      this.dataSource = new MatTableDataSource(this.eventDetails);
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public pageEvent(event: PageEvent): void {
    this.eventDetails = [];
    this.paginatedData = event;
    this.pageData.emit(event);
  }

  public switchView(event: { value: string }): void {
    this.viewMode = event.value;
  }

  public allFilesSelected(): boolean {
    if (this.eventDetails.length === this.selectedFiles.length) {
      this.checkAll = true;

      return true;
    } else {
      this.checkAll = false;

      return false;
    }
  }

  public selectAllFiles(event: MatCheckboxChange): void {
    if (event.checked) {
      this.selectedFiles = this.eventDetails;
      this.eventDetails.forEach(eventDetail => {
        eventDetail['isChecked'] = true;
      });
    } else {
      this.eventDetails.forEach(eventDetail => {
        eventDetail['isChecked'] = false;
      });
    }
  }

  public selectEvent(event: MatCheckboxChange, selectedEvent: ReportEventDetail): void {
    if (event.checked) {
      this.selectedFiles.push(selectedEvent);
      selectedEvent['isChecked'] = true;
    } else {
      this.selectedFiles = this.selectedFiles.filter(file => file.id !== selectedEvent.id);
      selectedEvent['isChecked'] = false;
    }
  }

  public onImageClick(index: number): void {
    this.isOpen = !this.isOpen;
    this.selectedPopOverIndex = index;
    const mapData: EventDetails = this.imageEventDetails[index];
    this.checkNext();
    this.popOverCameraDetails = mapData;
  }

  public onEventClick(event: number): void {
    const action = event === this.selectedPopOverIndex - 1 ? 'prev' : 'next';
    this.selectedPopOverIndex = event;
    const pageEvent = this.paginatedData
      ? this.paginatedData
      : ({
          pageIndex: 0,
          previousPageIndex: 0,
          pageSize: 10,
          length: this.totalRecords,
        } as PageEvent);
    this.checkNext();
    this.checkPrevious(pageEvent);
    if (this.eventDetails[this.selectedPopOverIndex]) {
      const data: EventDetails = this.imageEventDetails[this.selectedPopOverIndex];
      this.popOverCameraDetails = data;
    } else if (this.hasNext) {
      pageEvent.pageIndex = action === 'next' ? pageEvent.pageIndex + 1 : pageEvent.pageIndex - 1;
      this.selectedPopOverIndex = action === 'next' ? 0 : pageEvent.pageSize - 1;
      this.pageData.emit(pageEvent);
    }
  }

  public checkNext(): void {
    this.hasNext = this.imageEventDetails.length > this.selectedPopOverIndex + 1;
  }

  public checkPrevious(pageEvent: PageEvent): void {
    this.hasPrevious = this.selectedPopOverIndex <= 0 && pageEvent.pageIndex === 0 ? false : true;
  }

  public updateParentData(event: EventDetails): void {
    this.imageEventDetails[this.selectedPopOverIndex] = event;
  }

  public closeCameraPopOver(): void {
    this.popOverCameraDetails = null;
    this.isOpen = false;
    this.cameraPopOverClosed.emit();
  }

  ngOnDestroy(): void {
    this.cameraProfileService.setEventTime('');
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public downloadSelectedFile(): void {
    const downloadUrls: number[] = [];
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(file => {
        downloadUrls.push(file.id);
      });
      if (downloadUrls && downloadUrls.length > 0) {
        this.downloadFiles(downloadUrls);
      } else {
        this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
      }
    }
  }

  public downloadCSV(): void {
    const startDate = this.calculateTimeBasedOnTimezone(this.startDate);
    const endDate = this.calculateTimeBasedOnTimezone(this.endDate);
    const recordsPerPage = 10000;
    const pageNumber = 1;
    this.isDownloadStarted = true;
    this.messageService.add({ severity: SlbSeverity.Info, summary: CameraConstants.CSV_DOWNLOAD_WAIT_MSG, sticky: true, closable: false });
    let csvData = '';
    let blob = new Blob();
    this.reportService
      .getReportsAllEventData(startDate, endDate, this.showAllEvents, pageNumber, recordsPerPage, this.timeZone, this.equipmentId)
      .pipe(
        tap((value: ReportEventDataIteration) => {
          const columnNames = REPORT_EVENT_CSV_DOWNLOAD_HEADING;

          if (!value?.total || !columnNames?.length) {
            return;
          }
          const eventData: ReportEventDetail[] = JSON.parse(JSON.stringify(value.eventReportTableDetails));
          csvData = this.convertToCsv(csvData, eventData, columnNames);
          if (value.index === 0) {
            blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
          } else {
            blob = new Blob([blob, csvData], { type: 'text/csv;charset=utf-8;' });
          }

          if (value.index === value.totalPages - 1) {
            this.downloadData(blob);
          }
        }),
        catchError(() => {
          this.messageService.clear();
          this.messageService.add({ severity: SlbSeverity.Error, summary: CameraConstants.CSV_DOWNLOAD_ERROR_MSG });
          this.isDownloadStarted = false;

          return of<EventDataIteration>({} as EventDataIteration);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private calculateTimeBasedOnTimezone(date: Date): string {
    if (date) {
      const dateBdOnChosedTimezone = new DatePipe('en-Us').transform(date, 'EEEE, MMMM d, y, h:mm:ss a zzzz', this.timeZone);
      if (dateBdOnChosedTimezone) {
        const basedonCurrentTimezone: Date = new Date(dateBdOnChosedTimezone);

        return basedonCurrentTimezone.toISOString();
      }

      return '';
    }

    return '';
  }

  private convertToCsv(prevCsvData: string, objArray: ReportEventDetail[], headerList: string[]): string {
    let str = '';
    if (!prevCsvData) {
      let row = '';
      headerList.forEach((value: string) => {
        row += value.charAt(0).toUpperCase() + value.slice(1) + ',';
      });
      row = row.slice(0, -1);
      str += row + '\r\n';
    }

    str += this.extractData(objArray, headerList, this.timeZone);

    return str;
  }

  private extractData(objArray: ReportEventDetail[], headerList: string[], currentZone: string): string {
    let str = '';

    objArray.forEach((eventValues: ReportEventDetail) => {
      let line = '';
      headerList.forEach((header: string) => {
        line += this.getPropertyValue(eventValues, header, currentZone) + ',';
      });
      line = line.replace(/,\s*$/, '');
      str += line + '\r\n';
    });

    return str;
  }

  private getPropertyValue(value: ReportEventDetail, propertyValue: string, currentZone: string): string {
    switch (propertyValue) {
      case 'peopleCount':
        return value?.peopleCount?.toString() ?? '';

      case 'thumbnailFileUrl':
        return value.thumbnailFileUrl.split('download/')[1];

      case 'accumulativeTime':
        return value.accumulativeTime ? value.accumulativeTime.toString() : '';

      case 'avgPeopleCount':
        return value.avgPeopleCount ? value.avgPeopleCount.toString() : '';

      case 'entry':
        return value.entry ? value.entry.toString() : '';

      case 'exit':
        return value.exit ? value.exit.toString() : '';

      case 'cameraDownTime':
        return value.cameraDownTime ? value.cameraDownTime.toString() : '';

      case 'eventTime':
        const formattedEventTime = this.datePipe.transform(new Date(value.eventTime), 'MM/dd/yyyy hh:mm:ss a', currentZone);

        return formattedEventTime ? formattedEventTime : '';
      case 'reportingStartTime':
        const formattedReportingStartTime = value.reportingStartTime
          ? this.datePipe.transform(new Date(value.reportingStartTime), 'MM/dd/yyyy hh:mm:ss a', currentZone)
          : '';

        return formattedReportingStartTime ? formattedReportingStartTime : '';
      case 'reportingEndTime':
        const formattedReportingEndTime = value.reportingEndTime
          ? this.datePipe.transform(new Date(value.reportingEndTime), 'MM/dd/yyyy hh:mm:ss a', currentZone)
          : '';

        return formattedReportingEndTime ? formattedReportingEndTime : '';

      default:
        return value ? (value[propertyValue as keyof ReportEventDetail] as string) : '';
    }
  }

  private downloadData(blob: Blob): void {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    const filename = `EventDetails-${new Date().toLocaleString()}`;

    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.isDownloadStarted = false;
    this.messageService.clear();
  }

  private downloadFiles(eventId: number[]): void {
    this.cameraProfileService
      .downloadMediaAsZip('', '', eventId)
      .pipe(
        tap((data: string) => {
          if (data && data !== '') {
            this.downloadZipFile(data);
          } else {
            this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
          }
        }),
        catchError(err => {
          console.error(err);
          throw err;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private downloadZipFile(url: string): void {
    const blob = new Blob([url], {
      type: 'application/zip',
    });
    const bloburl = window.URL.createObjectURL(blob);
    const filename = 'SLB Edge-Vision Intelligence-global';
    const dwldLink = document.createElement('a');

    dwldLink.setAttribute('href', bloburl);
    dwldLink.setAttribute('download', filename + '.zip');
    dwldLink.setAttribute('target', '_blank');
    dwldLink.click();
    dwldLink.remove();
  }
}
