<div class="event-reports-chart-main-container">
  <div class="vi-report-chart-card">
    <mat-card *ngIf="!isLoading">
      <mat-card-title class="report-chart-title">
        <span>People at risk</span>
        <mat-card-subtitle *ngIf="selectedTimeRangeText.length">({{ selectedTimeRangeText }})</mat-card-subtitle>
      </mat-card-title>
      <highcharts-chart [Highcharts]="Highcharts" [options]="eventLineChartOption" [(update)]="updateEventLineChart"></highcharts-chart>
    </mat-card>
    <mat-card *ngIf="isLoading">
      <mat-card-content class="doughnut-chart-loader"> <app-spinner></app-spinner> </mat-card-content>
    </mat-card>
  </div>
  <div class="vi-report-chart-card">
    <mat-card *ngIf="!isLoading">
      <mat-card-title class="report-chart-title">
        <span>Event type distribution</span>
        <mat-card-subtitle *ngIf="selectedTimeRangeText.length">({{ selectedTimeRangeText }})</mat-card-subtitle>
      </mat-card-title>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="eventTypeDistributionChartOption"
        [(update)]="updateEventTypeDistributionChart"></highcharts-chart>
    </mat-card>
    <mat-card *ngIf="isLoading">
      <mat-card-content class="doughnut-chart-loader"> <app-spinner></app-spinner> </mat-card-content>
    </mat-card>
  </div>
</div>
