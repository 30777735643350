import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { ReportEventDetail } from '../../../../src/app/shared/models/report-view';
import { REPORT_DATE_FORMAT, REPORT_TILE_TITLES } from '../../../../src/app/shared/constants/report-constants';

@Component({
  selector: 'app-report-tiles',
  templateUrl: './report-tiles.component.html',
  styleUrls: ['./report-tiles.component.scss'],
})
export class ReportTilesComponent implements OnInit {
  @Input() lastReportingCycleData: ReportEventDetail;
  public tilesData: { name: string; value: string | number }[] = [];
  public reportStartTime: string;
  public reportEndTime: string;
  public noDataToDisplay: boolean;

  constructor() {}

  ngOnInit(): void {
    if (this.lastReportingCycleData) {
      this.tilesData.push({
        name: REPORT_TILE_TITLES.NO_OF_ENTRIES,
        value: this.lastReportingCycleData['entry'] ? this.lastReportingCycleData['entry'] : '-',
      });
      this.tilesData.push({
        name: REPORT_TILE_TITLES.NO_OF_EXITS,
        value: this.lastReportingCycleData['exit'] ? this.lastReportingCycleData['exit'] : '-',
      });
      this.tilesData.push({
        name: REPORT_TILE_TITLES.ACCUMULATED_TIME_RED_ZONE,
        value: this.lastReportingCycleData['accumulativeTime'] ? this.lastReportingCycleData['accumulativeTime'] : 0,
      });
      this.tilesData.push({
        name: REPORT_TILE_TITLES.PEOPLE_COUNT,
        value: this.lastReportingCycleData['peopleCount'] ? this.lastReportingCycleData['peopleCount'] : 0,
      });
      this.tilesData.push({
        name: REPORT_TILE_TITLES.AVG_TIME_REDZONE_PER_PERSON,
        value: this.lastReportingCycleData['avgTimeInRedZone'] ? this.lastReportingCycleData['avgTimeInRedZone'] : 0,
      });
      this.tilesData.push({
        name: REPORT_TILE_TITLES.MAX_PEOPLE_WORKING_SIMULTANEOUSLY_TOGETHER,
        value: this.lastReportingCycleData['accumulativeTime'] ? this.lastReportingCycleData['accumulativeTime'] : 0,
      });
      this.tilesData.push({
        name: REPORT_TILE_TITLES.CAMERA_DOWNTIME,
        value: this.lastReportingCycleData['cameraDownTime'] ? this.lastReportingCycleData['cameraDownTime'] : 0,
      });
      this.reportStartTime = this.lastReportingCycleData['reportingStartTime']
        ? moment(this.lastReportingCycleData['reportingStartTime']).format(REPORT_DATE_FORMAT)
        : '';
      this.reportEndTime = this.lastReportingCycleData['reportingEndTime']
        ? moment(this.lastReportingCycleData['reportingEndTime']).format(REPORT_DATE_FORMAT)
        : '';
      this.noDataToDisplay = false;
    } else {
      this.noDataToDisplay = true;
    }
  }
}
