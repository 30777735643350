<div class="vi-report-widgets">
  <div class="last-reporting-cycle">
    <mat-card>
      <p class="last-reporting-cycle-text">
        Last Reporting Cycle
        <span *ngIf="reportStartTime?.length && reportEndTime?.length">({{ reportStartTime }} to {{ reportEndTime }})</span>
      </p>
      <div class="cycle-data-tile-container" *ngIf="!noDataToDisplay">
        <div *ngFor="let tile of tilesData" class="cycle-data-tile">
          <p class="cycle-data-tile-value">{{ tile.value }}</p>
          <p class="cycle-data-tile-name">{{ tile.name }}</p>
        </div>
      </div>
      <div class="cycle-data-tile-container" *ngIf="noDataToDisplay">No data available</div>
    </mat-card>
  </div>
</div>
