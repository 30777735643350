import { Injectable } from '@angular/core';
import { EMPTY, expand, Observable, scan } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constant';
import { environment } from '../../../environments/environment';
import { Equipment } from '../models/equipment';
import { ReportDetail, ReportEventDataIteration } from '../models/report-view';
import { GatewayDetail } from '../models/gateway';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  public getEventReport(
    startDate: string,
    endDate: string,
    showAllEvents: string,
    pageNo: number,
    records: number,
    timeZone?: string,
    equipmentId?: string
  ): Observable<ReportDetail> {
    const param = {
      startDate,
      endDate,
      showAllEvents,
      pageNo,
      records,
      timeZone,
      equipmentId,
    };

    return this.http.post<ReportDetail>(`${this.baseUrl}${ApiConstants.BASEURL}event/get-event-report`, param);
  }

  public getGateways(): Observable<GatewayDetail[]> {
    return this.http.get<GatewayDetail[]>(`${this.baseUrl}${ApiConstants.BASEURL}dashboard/gateway-list/report`);
  }

  public getEquipmentList(): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(`${this.baseUrl}${ApiConstants.BASEURL}dashboard/equipment-list/report`);
  }

  public getReportsAllEventData(
    startDate: string,
    endDate: string,
    showAllEvents: string,
    pageNo: number,
    records: number,
    timeZone?: string,
    equipmentId?: string
  ): Observable<ReportEventDataIteration> {
    let totalPages = 0;

    return this.getEventReport(startDate, endDate, showAllEvents, pageNo, records, timeZone, equipmentId).pipe(
      expand((response: ReportDetail) => {
        const dataResponse: ReportDetail = JSON.parse(JSON.stringify(response));
        if (dataResponse.total && totalPages !== pageNo) {
          return this.getEventReport(startDate, endDate, showAllEvents, ++pageNo, records, timeZone, equipmentId);
        } else {
          pageNo = 1;
          this.getEventReport(startDate, endDate, showAllEvents, ++pageNo, records, timeZone, equipmentId);

          return EMPTY;
        }
      }),
      scan((accumulatedData: ReportEventDataIteration, currentData: ReportDetail, index: number) => {
        if (Object.keys(accumulatedData).length === 0) {
          totalPages = Math.round(currentData.total / records);
          totalPages = currentData.total % records ? ++totalPages : totalPages;
          accumulatedData = {
            ...currentData,
            index,
            totalPages,
          };
        } else {
          accumulatedData.eventReportTableDetails = [...currentData.eventReportTableDetails];
          accumulatedData.index = index;
        }

        return accumulatedData;
      }, {} as ReportEventDataIteration)
    );
  }
}
